// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    citiesList: [],
  },
  getters: {
    citiesList(state) {
      return state.citiesList
    },
  },
  mutations: {
    SET_CITIES(state, citiesList) {
      state.citiesList = citiesList
    },
  },
}

export default {
  namespaced: true,

  state: {
    propertiesList: [],
  },
  getters: {
    propertiesList(state) {
      return state.propertiesList
    },
  },
  mutations: {
    SET_PROPERTIES(state, propertiesList) {
      state.propertiesList = propertiesList
    },
  },
}

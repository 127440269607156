// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    closedContractsList: [],
    tiaContractsList: [],
    activeContractsList: [],
    lapsedContractsList: [],
    contractItem: null,
    isLoading: false,
    error: null,
  },
  getters: {
    closedContractsList(state) {
      return state.closedContractsList
    },
    tiaContractsList(state) {
      return state.tiaContractsList
    },
    activeContractsList(state) {
      return state.activeContractsList
    },
    lapsedContractsList(state) {
      return state.lapsedContractsList
    },
    contractItem(state) {
      return state.contractItem
    },
    isLoading(state) {
      return state.isLoading
    },
    error(state) {
      return state.error
    },
  },
  mutations: {
    SET_CLOSED_CONTRACTS(state, closedContractsList) {
      state.closedContractsList = closedContractsList
    },
    SET_TIA_CONTRACTS(state, tiaContractsList) {
      state.tiaContractsList = tiaContractsList
    },
    SET_ACTIVE_CONTRACTS(state, activeContractsList) {
      state.activeContractsList = activeContractsList
    },
    SET_LAPSED_CONTRACTS(state, lapsedContractsList) {
      state.lapsedContractsList = lapsedContractsList
    },
    SET_CONTRACT(state, contractItem) {
      state.contractItem = contractItem
    },
    /* REPLACE_USER(state, userItem) {
      const userItemIndex = state.usersList.map(item => item.id).indexOf(userItem.id)
      Vue.set(state.usersList, userItemIndex, userItem)
    },
    GET_USER(state, index) {
      const userItemIndex = state.usersList.map(item => item.id).indexOf(index)
      state.userItem = state.usersList[userItemIndex]
    }, */
    SET_LOADING_STATUS(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    /* REMOVE_ROW(state, index) {
      const removeIndex = state.usersList.map(item => item.id).indexOf(index)
      state.usersList.splice(removeIndex, 1)
    }, */
  },
  actions: {
    deleteRow({ commit }, dispatchData) {
      commit('REMOVE_ROW', dispatchData.id)
    },
  },
}

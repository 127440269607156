// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    insuranceProviderList: [],
    isLoading: false,
    error: null,
  },
  getters: {
    insuranceProviderList(state) {
      return state.insuranceProviderList
    },
    isLoading(state) {
      return state.isLoading
    },
    error(state) {
      return state.error
    },
  },
  mutations: {
    SET_INSURANCE_PROVIDERS(state, insuranceProviderList) {
      state.insuranceProviderList = insuranceProviderList
    },
    SET_LOADING_STATUS(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    /* REMOVE_ROW(state, index) {
      const removeIndex = state.usersList.map(item => item.id).indexOf(index)
      state.usersList.splice(removeIndex, 1)
    }, */
  },
  actions: {
    deleteRow({ commit }, dispatchData) {
      commit('REMOVE_ROW', dispatchData.id)
    },
  },
}

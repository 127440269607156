// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    rolesList: [],
  },
  getters: {
    rolesList(state) {
      return state.rolesList
    },
  },
  mutations: {
    SET_ROLES(state, rolesList) {
      state.rolesList = rolesList
    },
  },
  actions: {
    deleteRow({ commit }, dispatchData) {
      commit('REMOVE_ROW', dispatchData.id)
    },
  },
}

export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },
]

/*= ========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */
import Axios from '@/libs/axios'

const actions = {

  // ////////////////////////////////////////////
  // Public Actions
  // ////////////////////////////////////////////
  async get({ commit, dispatch }, payload) {
    // commit(`${payload.storeName}/SET_ERROR`, null)

    if (payload.needLoading) {
      commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
    }
    // const vm = this
    return new Promise((resolve, reject) => {
      Axios
        .get(payload.url)
        .then(response => {
          if (payload.dispatch_Func) {
            if (payload.dispatch_data) {
              dispatch(payload.dispatch_Func, payload.dispatch_data)
            } else {
              dispatch(payload.dispatch_Func)
            }
          } else if (payload.commitUrl) {
            commit(payload.commitUrl, response.data.data)
          }
          resolve(response)
        })
        .catch(error => {
          // eslint-disable-next-line no-prototype-builtins
          if (error.response.data.hasOwnProperty('message')) {
            // commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
            reject(error.response.data.message)
          }
        }).finally(() => {
          if (payload.needLoading) {
            commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
          }
          // vm.$forceUpdate()
        })
    })
  },

  async getLocalItem({ commit }, payload) {
    commit(payload.commitUrl, payload.id)
  },

  async changeFormStatus({ commit }, payload) {
    commit(payload.commitUrl, payload.status)
  },

  async add({ commit, dispatch }, payload) {
    // commit(`${payload.storeName}/SET_ERROR`, null)
    if (payload.needLoading) {
      commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
    }
    return new Promise((resolve, reject) => {
      let response
      if (payload.hasFormData) {
        response = Axios.post(`/${payload.url}`, payload.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
      } else {
        response = Axios.post(`/${payload.url}`, payload.form)
      }
      response.then(value => {
        if (payload.dispatch_Func) {
          if (payload.dispatch_data) {
            dispatch(payload.dispatch_Func, payload.dispatch_data)
          } else {
            dispatch(payload.dispatch_Func)
          }
        } else if (payload.commitUrl) {
          commit(payload.commitUrl, value.data.data)
        }
        resolve(value)
      }).catch(error => {
        // eslint-disable-next-line no-prototype-builtins
        if (error.response.data.hasOwnProperty('message')) {
          // commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
          reject(error.response.data.message)
        }
      }).finally(() => {
        if (payload.needLoading) {
          commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
        }
      })
    })
  },

  async edit({ commit, dispatch }, payload) {
    // commit(`${payload.storeName}/SET_ERROR`, null)
    if (payload.needLoading) {
      commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
    }
    return new Promise((resolve, reject) => {
      let response
      if (payload.hasFormData) {
        response = Axios.put(`/${payload.url}`, payload.formData,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
      } else {
        response = Axios.put(`/${payload.url}`, payload.form)
      }
      response.then(value => {
        if (payload.dispatch_Func) {
          if (payload.dispatch_data) {
            dispatch(payload.dispatch_Func, payload.dispatch_data)
          } else {
            dispatch(payload.dispatch_Func)
          }
        } else if (payload.commitUrl) {
          commit(payload.commitUrl, value.data.data)
        }
        resolve(value)
      }).catch(error => {
        // eslint-disable-next-line no-prototype-builtins
        if (error.response.data.hasOwnProperty('message')) {
          // commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
          reject(error.response.data.message)
        }
      }).finally(() => {
        if (payload.needLoading) {
          commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
        }
      })
    })
  },

  async delete({ commit, dispatch }, payload) {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm('Are you sure ?')) {
      // commit(`${payload.storeName}/SET_ERROR`, null)

      if (payload.needLoading) {
        commit(`${payload.storeName}/SET_LOADING_STATUS`, true)
      }
      Axios
        .delete(`/${payload.url}`)
        .then(() => {
          if (payload.dispatch_Func) {
            if (payload.dispatch_data) {
              dispatch(payload.dispatch_Func, payload.dispatch_data)
            } else {
              dispatch(payload.dispatch_Func)
            }
          } else if (payload.commmitUrl) {
            commit(payload.commmitUrl, payload.id)
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-prototype-builtins
          if (error.response.data.hasOwnProperty('message')) {
            // commit(`${payload.storeName}/SET_ERROR`, error.response.data.message)
          }
        }).finally(() => {
          if (payload.needLoading) {
            commit(`${payload.storeName}/SET_LOADING_STATUS`, false)
          }
        })
    }
  },

  async restore({ dispatch }, payload) {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm('Are you sure ?')) {
      try {
        await Axios.get(`/${payload.url}/${payload.id}/restore`)
        if (payload.dispatch_data) {
          dispatch(payload.dispatch_Func, payload.dispatch_data)
        } else {
          dispatch(payload.dispatch_Func)
        }
      } catch (error) {
        // console.log(error)
      }
    }
  },

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width)
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val)
  },
  updateWindowWidth({ commit }, width) {
    commit('UPDATE_WINDOW_WIDTH', width)
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  //  The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },
}

export default actions

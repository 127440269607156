// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    typesList: [],
  },
  getters: {
    typesList(state) {
      return state.typesList
    },
  },
  mutations: {
    SET_TYPES(state, typesList) {
      state.typesList = typesList
    },
  },
}

// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    statusesList: [],
  },
  getters: {
    statusesList(state) {
      return state.statusesList
    },
  },
  mutations: {
    SET_STATUSES(state, statusesList) {
      state.statusesList = statusesList
    },
  },
}

// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    commissionsList: [],
    isLoading: false,
    error: null,
  },
  getters: {
    commissionsList(state) {
      return state.commissionsList
    },
    isLoading(state) {
      return state.isLoading
    },
    error(state) {
      return state.error
    },
  },
  mutations: {
    SET_COMMISSIONS(state, commissionsList) {
      state.commissionsList = commissionsList
    },
    /* REPLACE_USER(state, userItem) {
      const userItemIndex = state.usersList.map(item => item.id).indexOf(userItem.id)
      Vue.set(state.usersList, userItemIndex, userItem)
    },
    GET_USER(state, index) {
      const userItemIndex = state.usersList.map(item => item.id).indexOf(index)
      state.userItem = state.usersList[userItemIndex]
    }, */
    SET_LOADING_STATUS(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    /* REMOVE_ROW(state, index) {
      const removeIndex = state.usersList.map(item => item.id).indexOf(index)
      state.usersList.splice(removeIndex, 1)
    }, */
  },
  actions: {
    deleteRow({ commit }, dispatchData) {
      commit('REMOVE_ROW', dispatchData.id)
    },
  },
}

import Axios from 'axios'
import store from './index'

store.subscribe(mutation => {
  switch (mutation.type) {
    case 'auth/SET_TOKEN':
      if (mutation.payload) {
        Axios.defaults.headers.common.Authorization = `Bearer${mutation.payload}`
        localStorage.setItem('token', mutation.payload)
      } else {
        Axios.defaults.headers.common.Authorization = null
        localStorage.removeItem('token')
      }
      break
    case 'auth/SET_CLIENT_DATA':
      if (mutation.payload) {
        localStorage.setItem('customer_id', mutation.payload.id)
      } else {
        localStorage.removeItem('customer_id')
      }
      break
    default:
  }
})

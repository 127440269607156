export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      pageTitle: 'Login',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forget-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/auth/ForgetPassword.vue'),
    meta: {
      pageTitle: 'Forget Password',
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/auth/ResetPassword.vue'),
    meta: {
      pageTitle: 'Reset Password',
      layout: 'full',
      resource: 'Auth',
    },
  },
]

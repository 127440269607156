export default {
  namespaced: true,

  state: {
    paymentFrequenciesList: [],
    isLoading: false,
    error: null,
  },
  getters: {
    paymentFrequenciesList(state) {
      return state.paymentFrequenciesList
    },
    isLoading(state) {
      return state.isLoading
    },
    error(state) {
      return state.error
    },
  },
  mutations: {
    SET_PAYMENT_FREQUENCIES(state, paymentFrequenciesList) {
      state.paymentFrequenciesList = paymentFrequenciesList
    },
    SET_LOADING_STATUS(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    /* REMOVE_ROW(state, index) {
      const removeIndex = state.usersList.map(item => item.id).indexOf(index)
      state.usersList.splice(removeIndex, 1)
    }, */
  },
  actions: {
    deleteRow({ commit }, dispatchData) {
      commit('REMOVE_ROW', dispatchData.id)
    },
  },
}
